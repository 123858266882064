import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import HeroImage from "../images/case-study-three-banner.jpg";
import ClientImage from "../images/client-img-three.jpg";
import CaseStudyCta from "../images/case-study-cta.png";
import CaseStudyCtaImg from "../images/case-study-cta-img.png";
 
import StatusUpIcon from "../assets/icons/status-up-icon.svg";
import TimeSpentIcon from "../assets/icons/time-spent-icon.svg";  
import SearchZoomInIcon from "../assets/icons/search-zoom-in-icon.svg";
import SearchAnalyticsIcon from "../assets/icons/search-analytics-icon.svg";
 
import SearchcontentIcon from "../assets/icons/search-content-icon.svg";  


const CaseStudyThreePage = () => {
    return (
        <div className="template-case-study case-study-three header-position">
            <Layout>
                <div className="hero-banner mb-104">
                    <div className="container"> 
                        <div className="hero-banner-wrap">
                            <div className="left-block">
                                <div className="banner-text">
                                    <div className="category-listing">
                                        <span class="extra-small-text font-light-color font-capital">Manufacturing</span>
                                        <span class="extra-small-text font-light-color font-capital">Site search</span>
                                        <span class="extra-small-text font-light-color font-capital">Product discovery</span>
                                    </div>
                                    <h2>Connecting shoppers to the right SKU with Product Discovery</h2>
                                    <p className="mb-40">How we helped our B2B client to increase search revenue by helping shoppers find the exact SKU within seconds ?</p>
                                    <ul class="highlighted-title-text d-flex">
                                        <li className="font-light-color font-medium font-capital"><h2 className="font-medium">4.5X</h2> MORE REVENUE FROM<br></br> SHOPPERS WHO USED SEARCH</li>
                                        <li className="font-light-color font-medium font-capital"><h2 className="font-medium">24%</h2> INCREASE IN SEARCH<br></br> CONVERSION RATE</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="right-block">
                                <img loading="lazy" src={HeroImage} title="Connecting shoppers to the right SKU with Product Discovery" alt="Connecting shoppers to the right SKU with Product Discovery" />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="image-title-section mb-208">
                    <div className="container-medium">
                        <div className="image-title-inner custom-row d-flex flex-wrap row-2 align-v-center">
                            <div className="title-block grid">
                                <h2 className="mb-24">The Client</h2>
                                <p>The Client is a top distributor of commercial doors, windows, and electronic access control equipment in the eastern United States. It is committed to providing high-quality products and elite customer service to its B2B clients. The Client desires to improve its eCommerce business.</p>
                            </div>
                            <div className="image-block grid">
                                <img loading="lazy" src={ClientImage} title="The Client" alt="The Client" />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="full-content-section mb-80">
                    <div className="container-small">
                        <div className="full-content-inner">
                            <h2 className="mb-24">The Challenges</h2>

                            <p>The Client was concentrating on growing its eCommerce revenue by creating a frictionless shopping experience from start to finish. To deliver the desired shopping experience to its B2B shoppers as they navigate the depths of its vast product catalog, having more than 30,000 SKUs. It needed an efficient way of powering relevant search experiences to drive online revenue.</p>
                            <p>The Client needed a platform with advanced search capabilities like</p>

                            <ul className="content-ul">
                                <li>Contextual search -  Understand product attribute nuances like 3 inches, 3”, or 3 in. </li>
                                <li>Search recommendation - Bundle multiple products together, like wooden doors with wooden frames and 6inch deck screws.</li>
                                <li>Synonyms - Display relevant results for keyword searches like doorknobs and door handles</li>
                                <li>Search personalization - Showing search results based on historical purchase data like preferred electronics locks brands.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="cta-banner case-study-top-cta mb-208">
                    <div className="container-small">
                        <div className="cta-banner-inner">
                            <div className="custom-row-big d-flex row-2 align-v-center">
                                <div className="grid">
                                    <div className="image-block">
                                        <img loading="lazy" src={CaseStudyCtaImg} title="Make your eCommerce more engaging with Experro Site Search." alt="Make your eCommerce more engaging with Experro Site Search." />
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="text-block"> 
                                        <h5 className="mb-32">Make your eCommerce more engaging with Experro Site Search.</h5>
                                        <Link className="button black-button button-small" to="/contact/">Talk to a CMS experts</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="full-content-section mb-208">
                    <div className="container-small">
                        <div className="full-content-inner">
                            <h2 className="mb-24">The Solution</h2>
                            <p>To stop the cycle of search abandonment for its shoppers and to deliver relevant search results every time, the Client turned to Experro AI-powered Search.</p>
                            <p className="font-bold font-dark-color mb-24">Digital Products</p>
                            <ul>
                                <li className="d-flex align-v-center mb-16">
                                    <div className="icon-block product-discovery">
                                        <SearchcontentIcon />
                                    </div>
                                    <p className="font-bold font-dark-color">Product Discovery</p>
                                </li> 
                            </ul>
                            <p>The implementation took just one month from beginning to end. With the Experro AI-powered Product Discovery powering the core search, integrated with the Experro Recommendation Engine was a perfect solution to enable personalized, relevant, and context-aware search results that avoided zero result pages every time.</p>
                        </div>
                    </div>
                </div>



                <div className="full-content-section mb-208">
                    <div className="container-small">
                        <div className="full-content-inner">
                            <h2 className="mb-24">The Results</h2>
                            <p>The Client has enhanced the digital experiences for its customers with a significantly revamped product discovery experience and saw qualitative improvements to its search experience and quantitative improvements to its conversion rate. </p>
                            <p>The strategy to show each customer the right product at the right time in the place they’re looking for proved successful. In comparing numbers from Q2 2021 to Q2 2022, the Client has seen.</p>
                            <div className="content-point-box">
                                <ul class="highlighted-title-text custom-row-big row-2 d-flex flex-wrap">
                                    <li className="font-light-color grid font-medium font-capital d-flex">
                                        <div className="icon-block">
                                            <StatusUpIcon />
                                        </div>
                                        <div className="content-title">
                                            <h3 className="font-medium">39%</h3>INCREASE IN Revenue <br></br>generated from search 
                                        </div> 
                                    </li>
                                    <li className="font-light-color grid font-medium font-capital d-flex">
                                        <div className="icon-block">
                                            <TimeSpentIcon />
                                        </div>
                                        <div className="content-title">
                                            <h3 className="font-medium">43.6%</h3>INCREASE IN time spent <br></br>on site after USING search
                                        </div> 
                                    </li>
                                    <li className="font-light-color grid font-medium font-capital d-flex">
                                        <div className="icon-block">
                                            <SearchZoomInIcon />
                                        </div>
                                        <div className="content-title">
                                            <h3 className="font-medium">18% </h3>decrease in search exits 
                                        </div> 
                                    </li>
                                    <li className="font-light-color grid font-medium font-capital d-flex">
                                        <div className="icon-block">
                                            <SearchAnalyticsIcon />
                                        </div>
                                        <div className="content-title">
                                            <h3 className="font-medium">24%</h3>INCREASE IN Search <br></br>Conversion rate
                                        </div> 
                                    </li>
                                </ul>
                            </div>
                        
                        </div>
                    </div>
                </div>

            
                <div className="cta-banner case-study-cta-banner mb-208">
                    <div className="container-medium">
                        <div className="cta-banner-inner">
                            <div className="custom-row-big d-flex row-2 align-v-center"> 
                                <div className="grid">
                                    <div className="text-block">
                                        <h4>To learn more about the Experro</h4>
                                        <p className="mb-40">We’re helping B2B, B2C, D2C, and retailers to reinvent their digital experiences for a customer-first future.</p>
                                        <Link className="button black-button" to="/contact/">Contact Us</Link>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="image-block">
                                        <img loading="lazy" src={CaseStudyCta} title="To learn more about the Experro" alt="To learn more about the Experro" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="experro-more-details-section mb-208">
                    <div className="container-medium">
                        <h3 className="mb-32 text-center font-medium">More Customer Success Stories</h3>
                        <div className="icon-with-text-block icon-with-text-border-box">
                            <div className="custom-row d-flex flex-wrap row-2">
                                <div className="grid">
                                    <div className="item-inner"> 
                                        <div className="text-block">
                                            <h6>Delivering Omnichannel Shopping Experience</h6>
                                            <p className="small-text">How we built consistent customer experiences by implementing the Digital Experience Platform in record time?</p>
                                            <Link className="btn-link right-arrow" to="/case-study-one/">Read Story</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="item-inner"> 
                                        <div className="text-block">
                                            <h6>Connecting shoppers and Product with AI Discovery</h6>
                                            <p className="small-text">How we helped our B2B client to increase search revenue by helping shoppers find the exact SKU within seconds?</p>
                                            <Link className="btn-link right-arrow" to="/case-study-two/">Read Story</Link>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>

                
            </Layout>
        </div>
        
    );
}

export default CaseStudyThreePage;